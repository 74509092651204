import * as React from "react"
import { navigate } from "gatsby"
import { Result, Button } from "antd"

const onClickBackHome = () => {
    navigate('/')
}

// markup
const NotFoundPage = () => {
    return (
        <Result
            status="404"
            title="404"
            subTitle="Sorry, the page you visited does not exist."
            extra={<Button type="primary" onClick={onClickBackHome}>Back Home</Button>}
        />
    )
}

export default NotFoundPage
